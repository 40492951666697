
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { DicDetail } from '@/types/dictionary'

@Component({
  name: 'DicAdd'
})
export default class extends Vue {
  private title = '新增数据字典'

  private info: DicDetail = {
    dicType: '',
    dicTypeDesc: '',
    pdicCode: '',
    dicCode: '',
    dicValue: '',
    dicExtCode: '',
    orderNum: '',
    editFlag: ''
  }

  get dicId () {
    return this.$route.params.dicId || ''
  }

  created () {
    if (this.dicId) {
      this.getData()
      this.title = '编辑数据字典'
    }
  }

  // 查询详情
  getData () {
    this.$axios.get<DicDetail>(this.$apis.dictionary.selectDicByDicId, { dicId: this.dicId })
      .then((res) => {
        this.info = res || this.info
      })
  }

  // 保存
  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        const url = this.dicId ? this.$apis.dictionary.updateDic : this.$apis.dictionary.insertDic
        this.$axios.post(url, this.info)
          .then(() => {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.$router.push({ name: 'dicList' })
          })
      }
    })
  }
}
